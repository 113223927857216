<template>
  <div class="container mb-8 mt-page">
    <!-- <p class="tag mb-3">{{ $t('community') }}</p> -->
    <h1 class="title">{{ $t('campuses') }}</h1>
    <p class="subtitle campuses-subtitle">{{ $t('my_campuses_subtitle') }}</p>

    <form action="" @submit.prevent="">
      <div class="app-input">
        <input type="text" v-model="query" :placeholder="$t('type_campus_name')" />
        <SvgSearch />
      </div>
    </form>

    <div class="campuses">
      <CardCampus class="card-campus" v-for="campus in campuses" :item="campus" :key="campus.id" />
    </div>
  </div>
</template>

<script>
import CardCampus from '../components/CardCampus.vue';
import CampusesService from '@/api/CampusesService';

export default {
  components: {
    CardCampus,
  },
  data() {
    return {
      campuses: [],
      query: '',
    };
  },

  mounted() {
    this.getCampuses();
  },
  watch: {
    query(newValue, oldValue) {
      this.getCampuses();
    },
  },

  methods: {
    async getCampuses() {
      const { data } = await CampusesService.searchCampuses(this.query);
      const { items } = data;
      this.campuses = items;
    },
  },
};
</script>

<style lang="scss" scoped>
.campuses {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  column-gap: 40px;
  row-gap: 20px;
  margin-top: 30px;
}

.card-campus {
  width: 100%;
}

.campuses-subtitle {
  max-width: 360px;
  margin-top: 28px;
}

.app-input {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: #f0f2f4;
  padding: 8px 16px;
  margin: 24px 0;
  width: 100%;
  max-width: 360px;

  @media (max-width: $sm) {
    max-width: unset;
  }

  input {
    border: none;
    background-color: transparent;
    flex: 1;
    font-size: 16px;

    &:focus-visible {
      outline: none;
    }
  }
}
</style>
