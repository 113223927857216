import { ApiClient } from './ApiClient.js';
const config = {
  transformResponse: [
    function (data) {
      return JSON.parse(data).data;
    },
  ],
};

export default {
  getCampuses() {
    return ApiClient.get('/campuses', config);
  },

  getCampus(id) {
    return ApiClient.get(`/campuses/${id}`, config);
  },

  searchCampuses(query) {
    return ApiClient.get(`/search/campuses?query=${query}`, config);
  },

  followCampus(id) {
    return ApiClient.post(`/campuses/${id}/followers`);
  },

  unfollowCampus(id) {
    return ApiClient.delete(`/campuses/${id}/followers`);
  },

  getEvents(id) {
    return ApiClient.get(`/campuses/${id}/events`, config);
  },

  getCampusEvents(id, page = 1) {
    return ApiClient.get(`/campuses/${id}/events?page=${page}`, config);
  },

  getCampusRecords(id, page = 1) {
    return ApiClient.get(`/records/campus/${id}?page=${page}`, config);
  },

  getCampusParticipants(id, page = 1) {
    return ApiClient.get(`/campuses/${id}/participants?page=${page}`, config);
  },

  createParticipant(campusId, userId, role) {
    return ApiClient.post(`/campuses/${campusId}/participants`, {
      userId,
      role,
    });
  },

  deleteParticipant(campusId, userId) {
    return ApiClient.delete(`/campuses/${campusId}/participants/${userId}`, config);
  },
};
